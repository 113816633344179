// Override Bootstrap's Sass default variables
//
// Nearly all variables in Bootstrap are written with the `!default` flag.
// This allows you to override the default values of those variables before
// you import Bootstrap's source Sass files.
//
// Overriding the default variable values is the best way to customize your
// CSS without writing _new_ styles. For example, change you can either change
// `$body-color` or write more CSS that override's Bootstrap's CSS like so:
// `body { color: red; }`.


//
// Bring in Bootstrap
//

// Option 1
//
// Import all of Bootstrap's CSS

@import "../node_modules/bootstrap/scss/functions"; // Required
$cyan: #0dcaf0;
$cyan-100: tint-color($cyan, 80%) !default;
$cyan-200: tint-color($cyan, 60%) !default;
$cyan-300: tint-color($cyan, 40%) !default;
$cyan-400: tint-color($cyan, 20%) !default;
$cyan-500: $cyan !default;
$cyan-600: shade-color($cyan, 20%) !default;
$cyan-700: shade-color($cyan, 40%) !default;
$cyan-800: shade-color($cyan, 60%) !default;
$cyan-900: shade-color($cyan, 80%) !default;

$primary: $cyan;
$secondary: $cyan-300;
$light: $cyan-100;
$dark: $cyan-900;

$enable-cssgrid: true;
$enable-gradients: true;
$enable-shadows: true;

$list-group-border-color:  $cyan;
$list-group-border-width:  .1rem;
$list-group-border-radius: .5rem;

@import "../node_modules/bootstrap/scss/bootstrap";

@include color-mode(dark) {
  $body-color: #eeeeee;
  $body-bg: $cyan-900;
  
  --#{$variable-prefix}body-color: #{$body-color};
  --#{$variable-prefix}body-bg: #{$body-bg};
}

//
// Custom styles
//
.container {
  padding: 0.5rem;
}

a {
  text-decoration: none;
}

// Style Bootstrap icons
.bi {
  vertical-align: -.125em;
  fill: currentColor;
}

.feature {
  color: $cyan;
}

.fw-bold {
  color: $cyan;
}

.feature-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  color: #fff;
  border-radius: .4rem;
}

.icon-link {
  display: inline-flex;
  align-items: center;
}
.icon-link > .bi {
  margin-top: .125rem;
  margin-left: .125rem;
  transition: transform .25s ease-in-out;
  fill: currentColor;
}
.icon-link:hover > .bi {
  transform: translate(.25rem);
}

.icon-square {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  font-size: 1.5rem;
  border-radius: .75rem;
}

.rounded-4 { border-radius: .5rem; }
.rounded-5 { border-radius: 1rem; }

.navbar-brand {
  font-weight: bold;
}

.veiledmail:after {
  content: attr(data-name) "@" attr(data-domain) "." attr(data-tld); 
}
